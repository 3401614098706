import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Debugging the OpenHAB IP Camera Binding",
  "path": "/Frequently_Asked_Question/Debugging_the_OpenHAB_IP_Camera_Binding/",
  "dateChanged": "2020-03-19",
  "author": "Mike Polinowski",
  "excerpt": "I installed the IP Camera Binding in OpenHAB 2.5 and I cannot get the camera snapshot to show up in the OpenHAB PaperUI.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='FAQs - Debugging the OpenHAB IP Camera Binding' dateChanged='2020-03-19' author='Mike Polinowski' tag='INSTAR IP Camera' description='I installed the IP Camera Binding in OpenHAB 2.5 and I cannot get the camera snapshot to show up in the OpenHAB PaperUI.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Debugging_the_OpenHAB_IP_Camera_Binding/' locationFR='/fr/Frequently_Asked_Question/Debugging_the_OpenHAB_IP_Camera_Binding/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "debugging-the-openhab-ip-camera-binding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#debugging-the-openhab-ip-camera-binding",
        "aria-label": "debugging the openhab ip camera binding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Debugging the OpenHAB IP Camera Binding`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I installed the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_IP_Camera_Binding/"
      }}>{`IP Camera Binding`}</a>{` in OpenHAB 2.5 and I cannot get the camera snapshot to show up in the OpenHAB PaperUI. How can I find out what is going wrong?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The OpenHAB Camera Binding allows you to take control over a couple of ONVIF functions that are available in our HD and Full HD camera models:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/adac0fa3d5bfef0dd2dc10d2c8300945/openHab_ip_camera_binding_01.gif",
        "alt": "Debugging the openHAB IP Camera Binding"
      }}></img></p>
    <p>{`In the case above we set up a "camera thing" to display a snapshot from our camera whenever the `}<strong parentName="p">{`Update the Image`}</strong>{` function is active.`}</p>
    <p>{`The camera was automatically detected by the camera binding (via the ONVIF service) and added by it's local IP address. Make sure that you add your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/User/"
      }}>{`camera admin login`}</a>{`, the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`HTTP Port`}</a>{` as well as the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/ONVIF/"
      }}>{`ONVIF Port`}</a>{`. In the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/ONVIF/"
      }}>{`ONVIF Menu`}</a>{` make sure that the ONVIF authentication is deactivated. The snapshot function is not set to be inactive as long as the `}<strong parentName="p">{`Update the Image`}</strong>{` function is deactivated:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/47aef/openHab_ip_camera_binding_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACAklEQVQ4y5WTS24TQRCGJ04kEoSIuAkbzsEVEErskAdsWLPgKFyEG7CCOBvimcEOwvYkM93T1Y/qqoKeODYx4fXrV09LXd/UQ92ZHN3jV7t8vCMvenK8I4fbcnSfX+7KyUM5eSAHW7KXyf5t9zfkeWbfPslosCkHPXX46P3rp+FgW/ob1O/RYJMGWzTY5P4G72Vrpv2ePMvaN48zPxny5OO30ed3H6wqhmH8KUyGYXy68GQYJme/Ol6cNcVpJgsxR+tZUP4u5rQaA1naMlMkA5Yi/RTxWwmlMNO22TIUwACAdc4AWGv5+v93Z+Yus1nARKSapm4a3cl7L/I/sFbKO58OeBUUQvDeO+e898ta1mFm1lpbAOzkvY8xMrMxqRffKXRatH0LJgIAIlqr0Dm37H+JrcMxxrquASClMunjnCNirRV087PWOucQ8Y7MaWCdUlAyeIcUhSlGRAwBA0ZEJrqrbGbw3iFGZmSOnf912nFe6VHejPK2LGk6uzbP5lLNebYyzeZ08ZWdExFEvIEnF3VVVbNZXVWgNbStM8YBjKcttC2Y1mjtAdLtmlfcqISs4PKLLssmL5o8vzofXZ2PVF7gdFoXY1WUqkhHuizddIp5weo2TIjq8tJpHYzxxlyv5L1EFEQJKCFwCIt913OM8SYzUaOUsRY6G2tdCPzHt/UD/g7c39z36LotkAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09ee65aa8caa28c998cfdd6e5d96f108/e4706/openHab_ip_camera_binding_02.avif 230w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/d1af7/openHab_ip_camera_binding_02.avif 460w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/7f308/openHab_ip_camera_binding_02.avif 920w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/29832/openHab_ip_camera_binding_02.avif 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/09ee65aa8caa28c998cfdd6e5d96f108/a0b58/openHab_ip_camera_binding_02.webp 230w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/bc10c/openHab_ip_camera_binding_02.webp 460w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/966d8/openHab_ip_camera_binding_02.webp 920w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/0ca5b/openHab_ip_camera_binding_02.webp 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/09ee65aa8caa28c998cfdd6e5d96f108/81c8e/openHab_ip_camera_binding_02.png 230w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/08a84/openHab_ip_camera_binding_02.png 460w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/c0255/openHab_ip_camera_binding_02.png 920w", "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/47aef/openHab_ip_camera_binding_02.png 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/09ee65aa8caa28c998cfdd6e5d96f108/c0255/openHab_ip_camera_binding_02.png",
              "alt": "Debugging the openHAB IP Camera Binding",
              "title": "Debugging the openHAB IP Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now add the location where you installed FFMPEG on your host system - defaults to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/usr/bin/ffmpeg`}</code>{`. And the path to a directory your camera can use for temporary files that are generated by FFMPEG - in this case I created a folder `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameratmpfs`}</code>{` inside `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/openhab/userdata`}</code>{` inside the openHAB installation directory:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/13729ca4dca32729187635e699edd9c9/47aef/openHab_ip_camera_binding_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "91.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACTklEQVQ4y42TO3PTQBSFNcOE4VUzMXQ0/CBqCmAyBJIuTmr+BD0tPf+DHicOxHn4KVvSSrK0j4+5K2+QExPYmTO7q9U999xzdyN27+H2H8PeA3gfwcf7uN0t+LDlv7v9R7i9h7ATwZsI3m7AuwheR1SfXxGZbgfX3SY/esHXT11mRy8xB0+pDjrUgm4Hc/S8wWEHc/ishQ7mYNuv7f4Tll92iKo8RRcJWRzz7XvO5ekZ6eCUxdkpyeAn2cUZy/EV9XTkUQVMhlTxBImv8oQ6TymzhIjVcFdD9OCY7PISlyrIVkgVLslQwxH5aIxZJGjBZIYZXNAetTZEzlq/sYuE+KTP5EeP9Ncv1OCc/PwCPR5jp1Pm/T7TXo9p75i432d+ckI1HDViPIejqioi51zzEciShEIpTFXhtMYZA9bijGYRz/yZriqs1iBnEuccgWOd0FryoiDPcw+lFCoXFBhjyVTmz8uy9CjKkrqu1whl/4fQOdI0ZT6fUxSFz9agxhiHtQattQ+SWWCMWSOURNeE1lqWy2WTvSiuIYR3jTZhHMfrhIvFgkwprywQlmWjsB28CTKkulbJDaF4J0pvqpBS7epG/JdCmbMs8x9ns5lfS3NEpXhVrpog6+DjTQ9vEUpwrpSfg9KgTAjFikD6b8JVU+THTWXJmSSSWfaS5KaHt5oi1yZJEm+u+ClrSRDsUKq5n81aeUsC8YamNEFCUtd6LbPMUvLf1N/poVp5GBBKDK+nWL0mmYP6wCFVrRGKulCqNKBtfGhE+5W0fZQhyX8DY1hF1mVNSrwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13729ca4dca32729187635e699edd9c9/e4706/openHab_ip_camera_binding_03.avif 230w", "/en/static/13729ca4dca32729187635e699edd9c9/d1af7/openHab_ip_camera_binding_03.avif 460w", "/en/static/13729ca4dca32729187635e699edd9c9/7f308/openHab_ip_camera_binding_03.avif 920w", "/en/static/13729ca4dca32729187635e699edd9c9/29832/openHab_ip_camera_binding_03.avif 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/13729ca4dca32729187635e699edd9c9/a0b58/openHab_ip_camera_binding_03.webp 230w", "/en/static/13729ca4dca32729187635e699edd9c9/bc10c/openHab_ip_camera_binding_03.webp 460w", "/en/static/13729ca4dca32729187635e699edd9c9/966d8/openHab_ip_camera_binding_03.webp 920w", "/en/static/13729ca4dca32729187635e699edd9c9/0ca5b/openHab_ip_camera_binding_03.webp 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13729ca4dca32729187635e699edd9c9/81c8e/openHab_ip_camera_binding_03.png 230w", "/en/static/13729ca4dca32729187635e699edd9c9/08a84/openHab_ip_camera_binding_03.png 460w", "/en/static/13729ca4dca32729187635e699edd9c9/c0255/openHab_ip_camera_binding_03.png 920w", "/en/static/13729ca4dca32729187635e699edd9c9/47aef/openHab_ip_camera_binding_03.png 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/13729ca4dca32729187635e699edd9c9/c0255/openHab_ip_camera_binding_03.png",
              "alt": "Debugging the openHAB IP Camera Binding",
              "title": "Debugging the openHAB IP Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`It is very important that those folders belong to the user `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhab`}</code>{` and group `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openhab`}</code>{` and that programs are allowed to read from and write to them:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "858px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a63465df6455554c1a50eed136fd93f1/42d54/openHab_ip_camera_binding_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABmElEQVQoz4WS2W6rMBBA+QIg2KFRCM2CMbaxx8vYQBslVf//pyryfMOVRqN5OZrlTEZiLMBLDkun6VHRoyJnTS6afkhayzX/OxSpeVZNkXam5JBLSziQq95F3EWstKN78Z5/wWRCIiyVlo6OcKhCqGasEKs50qumjdqC8wlLDoWwhbRFDyUzecI8hPwrlZ8jPbydfIWHEFhn2ADMOqYsu+nOe2Y9m5GdR7LfgEU2WNffdK9sr4APhl91H3zvPQ+eHSX52IBlViQsGawzK1tw2F10EXGNgLvDxrUlqYeMpEjkerBauZqb/VVXS6wmrDDst1RJuqpaYgmeM+O4sSPczrrqDO3MqroW//G8m2NlHNWOJqQh0NGutnwgAuhBbfzJChf4UuVC/pzye1qLJRZLzJdY9rC5M89aHz6Fbb0//U6nx9RK287xNMfTdzol3LfjFiwnVNYtCn60fXj3HOFp7BPsjzRwGevDW1uvzjMewDfWN4/U3GODoZliE7Bl5viSudXZ3hP/iiLhMOHgvRAgtBUKpPeiHbfhP2StdlUcDsP8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a63465df6455554c1a50eed136fd93f1/e4706/openHab_ip_camera_binding_04.avif 230w", "/en/static/a63465df6455554c1a50eed136fd93f1/d1af7/openHab_ip_camera_binding_04.avif 460w", "/en/static/a63465df6455554c1a50eed136fd93f1/1f8f9/openHab_ip_camera_binding_04.avif 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a63465df6455554c1a50eed136fd93f1/a0b58/openHab_ip_camera_binding_04.webp 230w", "/en/static/a63465df6455554c1a50eed136fd93f1/bc10c/openHab_ip_camera_binding_04.webp 460w", "/en/static/a63465df6455554c1a50eed136fd93f1/41e9e/openHab_ip_camera_binding_04.webp 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a63465df6455554c1a50eed136fd93f1/81c8e/openHab_ip_camera_binding_04.png 230w", "/en/static/a63465df6455554c1a50eed136fd93f1/08a84/openHab_ip_camera_binding_04.png 460w", "/en/static/a63465df6455554c1a50eed136fd93f1/42d54/openHab_ip_camera_binding_04.png 858w"],
              "sizes": "(max-width: 858px) 100vw, 858px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a63465df6455554c1a50eed136fd93f1/42d54/openHab_ip_camera_binding_04.png",
              "alt": "Debugging the openHAB IP Camera Binding",
              "title": "Debugging the openHAB IP Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we can add both the camera snapshot as well as the update switch to our dashboard and you should end up with the same view as shown in the animation above:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/aa61c/openHab_ip_camera_binding_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqklEQVQoz4VQTW/UMBTMXlroAYkDEu1K8AP4K1y5UzhUsD1U2t0e+QuIH8IdcWgREke48CG1h0pI7CZxNrHjbDb2e3l+xXU3GwoSo5GlJ894xi/ig4Eb7bjRLT4Y8MstfrHFhzt8eJtH2/x8wM+ia+6v+TTi0R3ej8zrx1E7HdJ02Ewfvn11JI4f0fgejIcw2cPJEMe7OL5/xd12stcRjx+4o7v5mycRLlW7lE2lT37yYlE2RVLlyTJPA+tCVHlqdAFLtWElaaUXySxiZsceBtTn9As44v/BOe+QUkbsnJ+ItC6zLF2taucoXAdlEPTpyAfIovDJdKV8d0anZ7YQszQVpZJgwVqLiP1MIgrOdbKv4YdY81xCLuJ5pn7EQC0iIhH9XTicRUjuQ0ppAAvdWGsBfHhAMJSq7LrUdb2p/fHCfboAtYiFEEk815VGbG887Wuv14GIm9pfY/oeA5q6MSbE3vhwKNyZAWCT/P6cPpybPP2VpJkQaVVVvZ3/I/naHCa5cnmFuYhnWfltjo7aIO3QreoPcx9KKcBW1wasNev+Acyste7+8tt8CSyWvNwyLtVjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2530f2b028ee4a5f2287d9c75d4b968f/e4706/openHab_ip_camera_binding_05.avif 230w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/d1af7/openHab_ip_camera_binding_05.avif 460w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/7f308/openHab_ip_camera_binding_05.avif 920w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/2cb71/openHab_ip_camera_binding_05.avif 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2530f2b028ee4a5f2287d9c75d4b968f/a0b58/openHab_ip_camera_binding_05.webp 230w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/bc10c/openHab_ip_camera_binding_05.webp 460w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/966d8/openHab_ip_camera_binding_05.webp 920w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/fa9eb/openHab_ip_camera_binding_05.webp 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2530f2b028ee4a5f2287d9c75d4b968f/81c8e/openHab_ip_camera_binding_05.png 230w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/08a84/openHab_ip_camera_binding_05.png 460w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/c0255/openHab_ip_camera_binding_05.png 920w", "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/aa61c/openHab_ip_camera_binding_05.png 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2530f2b028ee4a5f2287d9c75d4b968f/c0255/openHab_ip_camera_binding_05.png",
              "alt": "Debugging the openHAB IP Camera Binding",
              "title": "Debugging the openHAB IP Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "what-can-i-do-if-it-does-not-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-can-i-do-if-it-does-not-work",
        "aria-label": "what can i do if it does not work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What can I do if it does not work?`}</h2>
    <p>{`First, make sure that FFMPEG is installed and is installed inside the directory that you added in the camera configuration in OpenHAB:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ffmpeg
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`which`}</span>{` ffmpeg`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "847px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed31a7bb6c1cc4fb385401e50543334e/b2cef/openHab_ip_camera_binding_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABXElEQVQoz2VQi26jQAzkD4ptWkLSPFoCB6H7toHQVOr9/0+dHKo7XSuNrPHujOVxdhj8aUq98zfvG+PLzmFr6eWNWku10dpaamyxuRTVpaj6YrsSRUYm4CJw5fyqFSShC8gROKGP6AKMDClBiBgicIQUMd7hQqZPE8MiavgiCa4CM+PISkZGSXAbcWK8MryLyiaGD8kwJZjvonfBmdW5CIpOgYX1a2KYRdsv2agLLozCGdqIPlBj8eLRBOwdGm1pTb6S0xv9cvRqFOstzoZqk1Hr8s+JzgZtQEnq56j3eOyKp16xkp/tY6dm+D2T8UVtyAWqLfqgJy37YnNXl/9j869mOLj8Q3LnH3r3wDEfLBhPuwtWHW364nlQ7O4ovw/KKsOVkVOc2jSdLR/NuG3i9sXvO9nVEY+DBj4qNMs3c+O4vc1eJmljqN354Kq9q57N/uC3e0d/o/7cv+z/AJ59U8UmiDxnAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed31a7bb6c1cc4fb385401e50543334e/e4706/openHab_ip_camera_binding_06.avif 230w", "/en/static/ed31a7bb6c1cc4fb385401e50543334e/d1af7/openHab_ip_camera_binding_06.avif 460w", "/en/static/ed31a7bb6c1cc4fb385401e50543334e/97edd/openHab_ip_camera_binding_06.avif 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed31a7bb6c1cc4fb385401e50543334e/a0b58/openHab_ip_camera_binding_06.webp 230w", "/en/static/ed31a7bb6c1cc4fb385401e50543334e/bc10c/openHab_ip_camera_binding_06.webp 460w", "/en/static/ed31a7bb6c1cc4fb385401e50543334e/8a8c4/openHab_ip_camera_binding_06.webp 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed31a7bb6c1cc4fb385401e50543334e/81c8e/openHab_ip_camera_binding_06.png 230w", "/en/static/ed31a7bb6c1cc4fb385401e50543334e/08a84/openHab_ip_camera_binding_06.png 460w", "/en/static/ed31a7bb6c1cc4fb385401e50543334e/b2cef/openHab_ip_camera_binding_06.png 847w"],
              "sizes": "(max-width: 847px) 100vw, 847px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed31a7bb6c1cc4fb385401e50543334e/b2cef/openHab_ip_camera_binding_06.png",
              "alt": "Debugging the openHAB IP Camera Binding",
              "title": "Debugging the openHAB IP Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now login to the OpenHAB console to see if there are any errors showing up when you try using your dashboard. You can do this by using SSH (if it is missing install it with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apt install ssh`}</code>{`), accepting the security key, using the default password `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`habopen`}</code>{` and starting `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`log:tail`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ssh`}</span>{` -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8101`}</span>{` openhab@localhost
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`yes`}</span>{`
habopen
log:tail`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "855px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b8493e253c43939bdace563bb468155/77800/openHab_ip_camera_binding_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACbklEQVQ4y4WR607bQBCF/QYl9s7Eudlx4sTeXa9vIRhMAgnhUshNzqVQKPT936IaQ4SqIlU6Ws3+OPudM6uxfngkYtENht0g6atERAM/VI7UG8KwpWFJZklmBzQgZyZnpmCmMJqS1YVWyYdHl1nlMtPzIYxO2DSDWWbkQzMb8GxQSVN9MDC8iDUlq/JPIZ3at3SAQXwsIumHRGvJd0FL1hqC1QSrl6r6f5lLaWZdNmuih9xFzqzS2ZSUDXwDDxDk/zrJ7JqiZ4p2TbbrEhxleJHRD8lc5f+VBrY0XKVbkrWk4ShdJXqcGnbA4MD8Gkuva7pMGtmxFSXAVSWN9UGsB0oPQ6PDDZsbncDoSFb1GH4hDfwHz7uT/n3DyVlvAt4E3CvmToF/B/eCWQnr5sw+Y/YpWKdAZwbNYzqtoQZiXVU7U+1AbjB8BLHG8AfGz5j+Rlmg3KIsoDsBdwa9W+hdQ3cK3SvoXIJ9qiGfg9qD2iNfQFAAn2OwQbXF5BX4Eno3ZGiPwBlD+xycEc1WBu2czIQKCgyfwLunQRbEVztM34AvyNC/I5o7Jbg7o9kZUxbnXAO+oJxyg2JNQP+hfGuP8QuKFQZb9OfEccbQuSjJJd8+gxZ1XqHaEc2fY7A9XPeYvKJYQu8avYey89WHiHxBndt5SQ4KIvAlyALEkszBFqNnFKtyT9eEcsYHjcrCOdiZBnJdbmgHfElkuaL9UexfKNbQv0Xv/pNMq558VCCyvyBOsCGzLGj579uOX9Bf0Lb6N2XPkvne2T4juHWi0fdSyJ/0SWpPKaInDB8xfUO5BndGdSj8DfRv6SH38NXO6A9urpxFXvetagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b8493e253c43939bdace563bb468155/e4706/openHab_ip_camera_binding_07.avif 230w", "/en/static/8b8493e253c43939bdace563bb468155/d1af7/openHab_ip_camera_binding_07.avif 460w", "/en/static/8b8493e253c43939bdace563bb468155/09f2b/openHab_ip_camera_binding_07.avif 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b8493e253c43939bdace563bb468155/a0b58/openHab_ip_camera_binding_07.webp 230w", "/en/static/8b8493e253c43939bdace563bb468155/bc10c/openHab_ip_camera_binding_07.webp 460w", "/en/static/8b8493e253c43939bdace563bb468155/31c30/openHab_ip_camera_binding_07.webp 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b8493e253c43939bdace563bb468155/81c8e/openHab_ip_camera_binding_07.png 230w", "/en/static/8b8493e253c43939bdace563bb468155/08a84/openHab_ip_camera_binding_07.png 460w", "/en/static/8b8493e253c43939bdace563bb468155/77800/openHab_ip_camera_binding_07.png 855w"],
              "sizes": "(max-width: 855px) 100vw, 855px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b8493e253c43939bdace563bb468155/77800/openHab_ip_camera_binding_07.png",
              "alt": "Debugging the openHAB IP Camera Binding",
              "title": "Debugging the openHAB IP Camera Binding",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should see a new log entry every time the camera binding loads a new snapshot from your camera. If there is a problem - it should show up here.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      